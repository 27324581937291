<template>
  <div class="mb-3">
    <label :class="error ? 'is-invalid' : ''" v-bind:for="fieldname">{{ label }}</label>

    <pre role="textbox"
        :contenteditable="! disabled"
        :type="type"
        class="form-control textarea"
        :class="error ? 'is-invalid' : ''"
        :id="fieldname"
        @blur="updateVal"
        @keyup.ctrl.enter="$emit('formSubmitRequest')"
    >{{ modelValue }}</pre>

    <div v-if="error" class="invalid-feedback">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FormGroupTextarea",
  data() {
    return {
      options: [],
      error: null,
    }
  },
  setup(props) {
    let d = props.cfg.edit ? (props.cfg.edit.disabled === undefined ? false : props.cfg.edit.disabled) : false;
    return {
      disabled: d
    }
  },
  watch: {
    validation: function () {
      this.error = this.getValidationError();
    },
  },
  methods: {
    updateVal(e) {
      this.$emit('update:modelValue', e.target.innerText)
    },
    getValidationError() {
      if (! this.validation.$error) return null;

      let fieldError = null;

      this.validation.$errors.forEach(error => {
        if (error.$property == this.fieldname) {
          fieldError = error;
        }
      });

      return fieldError;
    },
  },
  props: {
    cfg: null,
    value: null,
    validation: null,
    label: String,
    fieldname: String,
    modelValue: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    }
  },
  emits: ['update:modelValue', 'formSubmitRequest'],
}
</script>
