<template>
  <div class="mb-3">
    <label :class="error ? 'is-invalid' : ''" :for="fieldname">{{ cfg.label }}</label>
    <input
        :type="getInputType(cfg)"
        :readonly="cfg.edit ? (cfg.edit.readonly ? 'readonly' : false) : false"
        :disabled="disabled"
        class="form-control"
        :class="error ? 'is-invalid' : ''"
        :id="fieldname"
        v-model="value"
        :min="cfg.edit ? (cfg.edit.min ? cfg.edit.min : false) : false"
        :step="cfg.edit ? (cfg.edit.step ? cfg.edit.step : false) : false"
        @keyup.ctrl.enter="$emit('formSubmitRequest', value)"
    />
    <div v-if="error" class="invalid-feedback">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>

import getInputType from "@/mixins/getInputType";

export default {
  mixins: [getInputType],
  setup(props) {
    let d = props.cfg.edit ? (props.cfg.edit.disabled === undefined ? false : props.cfg.edit.disabled) : false;
    return {
      disabled: d
    }
  },
  watch: {
    validation: function () {
      this.error = this.getValidationError();
    },
  },
  data() {
    return {
      error: null
    }
  },
  methods: {
    getValidationError() {
      if (! this.validation.$error) return null;

      let fieldError = null;

      this.validation.$errors.forEach(error => {
        if (error.$property == this.fieldname) {
          fieldError = error;
        }
      });

      return fieldError;
    },
  },
  name: "FormGroupInput",
  props: {
    cfg: null,
    validation: null,
    fieldname: String,
    modelValue: null,
  },
  emits: ['update:modelValue', 'formSubmitRequest'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
