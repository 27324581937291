<template>
  <div class="edit-form">
    <div class="tile" ref="editortile">
      <VueLoading
        :active="isLoading"
        :is-full-page="false"
      />
      <h4 v-if="currentEntity.company_name">{{ currentEntity.company_name }}</h4>
      <h4 v-else>{{ modelConfig.name }}</h4>
      <form v-if="currentEntity" @submit.prevent="">
        <FormFields
          ref="formFields"
          v-model="currentEntity"
          :dataService="dataService"
          :modelConfig="modelConfig"
          :validation="v$"
          @formSubmitRequest="handleSubmit"
        />

        <slot name="before_buttons" />

        <div class="pt-3">
          <button ref="button_back_to_list" v-show="backRoute" type="button" class="btn btn-primary" @click="$router.push(backRoute)">
            <font-awesome-icon :icon="['fas', 'arrow-left']" />
            <span>Back</span>
          </button>
          <button ref="button_update" v-show="currentEntity.id" type="button" class="btn btn-success float-end" @click="handleSubmit">Update</button>
          <button ref="button_create" v-show="! currentEntity.id" type="button" class="btn btn-success float-end" @click="handleSubmit">Create</button>
          <button ref="button_delete" v-show="currentEntity.id" type="button" class="btn btn-danger float-end" @click="deleteRecord">Delete</button>
          <button
            v-for="(cfg, prop) in buttons"
            :key="prop"
            scope="col"
            :class="'btn btn-' + cfg.class"
            :ref="'button_' + prop"
            @click="cfg.click"
          >
            <font-awesome-icon v-if="cfg.icon" :icon="cfg.icon" />
            {{ cfg.text }}

          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import FormFields from "@/components/Form/FormFields";
import {useVuelidate} from "@vuelidate/core";
import handleRequestError from "@/mixins/handleRequestError";
import VueLoading from 'vue-loading-overlay';
import getDateString from "@/mixins/getDateString";
import disableFormFields from "@/mixins/disableFormFields";
import enableFormFields from "@/mixins/enableFormFields";
import disableFormButtons from "@/mixins/disableFormButtons";
import enableFormButtons from "@/mixins/enableFormButtons";

export default {
  name: "ModelForm",
  mixins: [handleRequestError, getDateString, disableFormFields, disableFormButtons, enableFormButtons, enableFormFields],
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    buttons: null,
    formFields: null,
    apiSlug: {
      type: String,
      required: true
    },
    vueSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      backRoute: this.$route.meta ? this.$route.meta.defaultBackRoute : null,
      isLoading: false,
      dataService: null,
      currentEntity: null,
      onAfterCreateHandlers: [],
      onAfterUpdateHandlers: [],
    };
  },
  emits: ['mainRecordLoaded'],
  components: {
    FormFields,
    VueLoading
  },
  methods: {
    getRecord(id) {
      this.isLoading = true;
      this.dataService.get(id)
        .then(response => {
          this.currentEntity = response.data;
          this.$emit('mainRecordLoaded', response.data);
          this.isLoading = false;
          // apply validation here if record exists already
          if (this.v$) {
            this.v$.$touch();
          }
        }).catch(e => {
          this.isLoading = false;
          this.handleRequestError(e);
        });
    },
    handleSubmit() {
      const that = this;
      // validate if validation is defined
      if (this.v$) {
        // apply validation here if record is new, otherwise it has been already touched in getRecord()
        if (! this.currentEntity.id) {
          this.v$.$touch();
        }
        // do not submit if errors exist
        if (this.v$.$error) {
          return;
        }
      }
      this.isLoading = true;
      if (this.currentEntity.id) {
        this.dataService.update(this.currentEntity.id, this.currentEntity)
          .then(response => {
            that.currentEntity = response.data;
            that.$emit('mainRecordLoaded', response.data);
            that.isLoading = false;
            that.$store.state.messages.push({title:"Success", type:"success", message: 'The record was updated successfully!'});
            that.onAfterUpdateHandlers.forEach(h => {
              h(response.data);
            });
          }).catch(e => {
            this.isLoading = false;
            this.handleRequestError(e);
        });
      } else {
        this.dataService.create(this.currentEntity)
          .then(response => {
            that.currentEntity = response.data;
            that.isLoading = false;
            that.$store.state.messages.push({title:"Success", type:"success", message: 'The record was created successfully!'});
            that.$router.push({ path: '/' + this.vueSlug + '/' + response.data.id })
            that.onAfterCreateHandlers.forEach(h => {
              h(response.data);
            });
          }).catch(e => {
            this.isLoading = false;
            this.handleRequestError(e);
        });
      }
    },
    deleteRecord() {
      const that = this;
      this.isLoading = true;
      this.dataService.delete(this.currentEntity.id)
        .then(function () {
          that.$store.state.messages.push({title:"Success", type:"success", message: 'The record was deleted successfully!'});
          that.isLoading = false;
          that.$router.push({ name: that.vueSlug });
        }).catch(e => {
          this.isLoading = false;
          this.handleRequestError(e);
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      // load record if route has an id
      this.getRecord(this.$route.params.id);
    }

    // new record with given parent id
    const pid = this.$route.params.parent_id;
    const pp = this.dataService.modelConfig.parentProperty;
    if (pid && pp) {
      this.currentEntity[pp] = pid;
    }
  }
};
</script>
