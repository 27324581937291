<template>
  <div class="mb-3">
    <label>{{ label }}</label>
    <select class="form-control" :id="fieldname" v-model="value" @keyup.ctrl.enter="$emit('formSubmitRequest')">
      <option value="1">Yes</option>
      <option value="0">No</option>
    </select>
  </div>
</template>

<script>

export default {
  name: "FormGroupBool",
  props: {
    label: String,
    fieldname: String,
    modelValue: null,
  },
  emits: ['update:modelValue', 'formSubmitRequest'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
