export default {
    methods: {
        // disable all form buttons except the given
        disableFormButtons(except = []) {
            for (const [p, o] of Object.entries(this.$refs)) {
                if (p.startsWith('button_')) {
                    const buttonname = p.substr(7);

                    // do not disable back button in any case
                    if (buttonname == "back_to_list") continue;

                    // disable if not excepted
                    if (except.indexOf(buttonname) === -1) {
                        o.disabled = "disabled";
                    }
                }
            }
        }
    }
}
