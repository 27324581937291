<template>
  <div class="row">
    <div v-for="(cfg, prop) in modelConfig.fields" :key="prop" scope="col" :class="cfg.edit ? cfg.edit.class : null">
      <FormGroupSelect
          :ref="'field_' + prop"
          v-if="getInputType(cfg) == 'select'"
          v-model="value[prop]"
          :fieldname="prop"
          :label="cfg.label"
          :titleProperty="cfg.resolve.titleProperty"
          :optionsDataService="cfg.resolve.optionsDataService"
          :validation="validation"
          :cfg="cfg"
          @formSubmitRequest="$emit('formSubmitRequest')"
      />
      <FormGroupInput
          :ref="'field_' + prop"
          v-if="['text', 'date', 'number', 'time', 'datetime-local'].indexOf(getInputType(cfg)) > -1"
          v-model="value[prop]"
          :fieldname="prop"
          :validation="validation"
          :cfg="cfg"
          @formSubmitRequest="$emit('formSubmitRequest')"
      />
      <FormGroupBool
          :ref="'field_' + prop"
          v-if="getInputType(cfg) == 'bool'"
          v-model="value[prop]"
          :fieldname="prop"
          :label="cfg.label"
          :validation="validation"
          @formSubmitRequest="$emit('formSubmitRequest')"
      />
      <FormGroupTextarea
          :ref="'field_' + prop"
          v-if="getInputType(cfg) == 'textarea'"
          v-model="value[prop]"
          :fieldname="prop"
          :label="cfg.label"
          :validation="validation"
          :cfg="cfg"
          @formSubmitRequest="$emit('formSubmitRequest')"
      />
      <FormGroupRichtext
          :ref="'field_' + prop"
          v-if="getInputType(cfg) == 'richtext'"
          v-model="value[prop]"
          :fieldname="prop"
          :label="cfg.label"
          :validation="validation"
          @formSubmitRequest="$emit('formSubmitRequest')"
      />
      <FormGroupEnum
          :ref="'field_' + prop"
          v-if="getInputType(cfg) == 'enum'"
          v-model="value[prop]"
          :fieldname="prop"
          :cfg="cfg"
          :validation="validation"
          @formSubmitRequest="$emit('formSubmitRequest')"
      />
    </div>
  </div>
</template>

<script>

import FormGroupInput from '@/components/Form/FormGroup/Input.vue';
import FormGroupSelect from '@/components/Form/FormGroup/Select.vue';
import FormGroupBool from "@/components/Form/FormGroup/Bool";
import FormGroupTextarea from "@/components/Form/FormGroup/Textarea";
import FormGroupRichtext from "@/components/Form/FormGroup/Richtext";
import getInputType from "@/mixins/getInputType";
import FormGroupEnum from "@/components/Form/FormGroup/Enum";

export default {
  mixins: [getInputType],
  props: {
    modelConfig: null,
    dataService: null,
    modelValue: null,
    validation: null,
  },
  emits: ['update:modelValue', 'formSubmitRequest'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  name: "FormFields",
  components: {
    FormGroupEnum,
    FormGroupRichtext,
    FormGroupTextarea,
    FormGroupBool,
    FormGroupInput,
    FormGroupSelect,
  },
}
</script>
