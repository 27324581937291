<template>
  <div class="mb-3">
    <label :class="error ? 'is-invalid' : ''" v-bind:for="fieldname">{{ label }}</label>
    <textarea :class="error ? 'is-invalid' : ''" ref="editor" class="form-control" v-bind:id="fieldname" v-model="value">
    </textarea>
    <div v-if="error" class="invalid-feedback">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: "FormGroupRichtext",
  props: {
    label: String,
    fieldname: String,
    modelValue: null,
    validation: null,
    type: {
      type: String,
      default: "text"
    },
  },
  emits: ['update:modelValue', 'formSubmitRequest'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  components: {
  },
  data() {
    return {
      error: null,
      ckeditor: null
    }
  },
  watch: {
    validation: function () {
      this.error = this.getValidationError();
    },
    value(v) {
      if (! this.ckeditor) {
        this.initCkEditor(v);
      }
    }
  },
  methods: {
    initCkEditor(v) {
      if (! v) {
        v = "";
      }
      var that = this;
      if (this.ckeditor) {
        return;
      }
      ClassicEditor
        .create(this.$refs.editor, {
          toolbar: ['bold', 'italic', 'link', 'numberedList', 'bulletedList', 'undo', 'redo']
        })
        .then(editor => {
          editor.model.document.on('change:data', () => {
            that.value = editor.getData();
          });

          editor.editing.view.document.on('keyup', function(e, f) {
            if (f.keyCode === 13 && f.ctrlKey) {
              editor.commands.get("undo").execute()
            }
          }, { priority: 'high' } );

          editor.setData(v);
          that.ckeditor = editor;
        })
        .catch(error => {
          console.error(error);
        });
    },
    getValidationError() {
      if (! this.validation.$error) return null;

      let fieldError = null;

      this.validation.$errors.forEach(error => {
        if (error.$property == this.fieldname) {
          fieldError = error;
        }
      });

      return fieldError;
    },
  },
  mounted() {
    if (this.$route.name.startsWith('new-')) {
      if (! this.ckeditor) {
        this.initCkEditor("");
      }
    }
  }
}
</script>
