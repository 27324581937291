<template>
  <div class="mb-3">
    <label :class="error ? 'is-invalid' : ''" v-bind:for="fieldname">
      <span>{{ label }}</span>
      <router-link v-if="modelValue && vueSlug" :to="'/' + vueSlug + '/' + value">
        <font-awesome-icon :icon="['fas', 'external-link-alt']" />
        <span>Go to this record</span>
      </router-link>
    </label>
    <select class="form-select" :disabled="disabled" :id="fieldname" v-model="value"
      :class="error ? 'is-invalid' : ''"
      @keyup.ctrl.enter="$emit('formSubmitRequest', value)"
      @keyup.backspace="setEmptyValue"
    >
      <option v-bind:value="option.id"
        :selected="{ selected: option.id == value }"
        v-for="(option, index) in options"
        :key="index"
      >{{ option[titleProperty] }}</option>
    </select>
    <div v-if="error" class="invalid-feedback">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>

import _ from "lodash";

export default {
  name: "FormGroupSelect",
  props: {
    label: String,
    fieldname: String,
    modelValue: null,
    optionsDataService: null,
    titleProperty: String,
    validation: null,
    cfg: null,
  },
  setup(props) {
    if (props.cfg.edit === false || props.cfg.edit === undefined) {
      return {
        disabled: props.cfg.edit
      }
    }
    return {
      disabled: props.cfg.edit.disabled
    }
  },
  data() {
    return {
      options: [],
      error: null,
      vueSlug: this.optionsDataService.vueSlug,
      fixedFilters: {}
    }
  },
  watch: {
    validation: function () {
      this.error = this.getValidationError();
    },
    fixedFilters: function() {
      this.search();
    }
  },
  methods: {
    getRecord(id) {
      let sr = null;
      this.options.forEach(record => {
        if (record.id == id) sr = record;
      });

      return sr;
    },
    setEmptyValue() {
      this.value=""
    },
    getValidationError() {
      if (! this.validation.$error) return null;

      let fieldError = null;

      this.validation.$errors.forEach(error => {
        if (error.$property == this.fieldname) {
          fieldError = error;
        }
      });

      return fieldError;
    },
    search() {
      this.optionsDataService.setFixedFilters(this.fixedFilters);
      this.optionsDataService.search().then(response => {
        let records = response.data.data;
        let el = {id: ''}
        el[this.titleProperty] = "";
        records.unshift(el);
        this.options = _.cloneDeep(records);

        // emit the selected record after fetching
        if (this.value) {
           records.forEach(r => {
             if (r.id == this.value) {
               this.$emit('currentModelRecord', r)
             }
           });
        }
      }).catch(e => {
        console.log(e);
      });
    },
  },
  emits: ['update:modelValue', 'formSubmitRequest', 'currentModelRecord'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
        this.$emit('currentModelRecord', this.getRecord(value))
      }
    }
  },
  created() {
    this.search()
  }
}
</script>
