export default {
    methods: {
        // enable all form buttons except the given
        enableFormButtons(except = []) {
            for (const [p, o] of Object.entries(this.$refs)) {
                if (p.startsWith('button_')) {
                    const buttonname = p.substr(7);

                    // disable if not excepted
                    if (except.indexOf(buttonname) === -1) {
                        o.disabled = "";
                    }
                }
            }
        }
    }
}
