export default {
    methods: {
        // disable all form fields except the given
        enableFormFields(except = []) {
            // this disables the already rendered fields
            if (! this.$refs.formFields) {
                return;
            }

            for (const [p, o] of Object.entries(this.$refs.formFields.$refs)) {
                if (p.startsWith('field_')) {
                    const fieldname = p.substr(6);
                    if (except.indexOf(fieldname) === -1) {
                        // TODO: does not work
                        o.disabled = false
                    }
                }
            }

            // this enables fields not rendered already
            for (let [fieldname, fieldConfig] of Object.entries(this.modelConfig.fields)) {
                if (except.indexOf(fieldname) === -1) {
                    // do not handle if edit is set to false in dataservice
                    if (fieldConfig.edit !== false) {
                        // this matches undefined or null
                        if (! fieldConfig.edit) fieldConfig.edit = { disabled: false }
                        fieldConfig.edit.disabled = false;
                    }
                }
            }
        }
    }
}
